import format from './format'

function formatPriceRange (price, separator, forceDecimals) {
    const { od_price, do_price } = price || {}
    return [od_price || 0, do_price || 0]
        .filter(val => val != null)
        .map(val => format(val, separator, forceDecimals))
        .join(' - ')
}

export default formatPriceRange