import Link from 'next/link'
import OfferShortSpec from './OfferShortSpec'

import {
	Equipment,
	Firm,
	Installment,
	IsNew,
	Location,
	OfferImage,
	PriceDetails,
	Title,

    extractOfferData,
} from './OfferListItemNewComponents'

import listAlt, { buyCaptionFor } from '@utils/alt/listAlt'
import offerLink from '@utils/links/offerLink'
import offerLinkBuy from '@utils/links/offerLinkBuy'
import { getSpec, getBuySpec } from './OfferShortSpec'
import mix from '@utils/styles/mix'

const HOST = process.env.NEXT_PUBLIC_HOST

import styles from './OfferListItemNew.module.css'

function OfferListItem(props) {
	const { offer } = props || {}
	if (offer == null) return null
	const isBuy = offer.type === 'buy_ad' || offer.isBuy
    const offerProps = extractOfferData(props)

	return isBuy
        ? <BuyOfferListItem {...offerProps} />
        : <SellOfferListItem {...offerProps} />
}

function SellOfferListItem(offerProps) {
    const {
        featured,
        forList,
        linkWithHost,
        offer,
        inject,
        isPart,

        // possible link props:
        className,
        style,
    } = offerProps

	const link = offerLink(offer)
	const specTextArray = getSpec(offer, true)
	const [alt, caption, bodyType] = listAlt(offer)
	const titleCaption = `${caption} ${bodyType}`.trim()
	const _className = mix([
		styles[forList ? 'list-item' : 'grid-item'],
		!featured && isHighlighted(offer) && styles.highlighted,
	])

	return (
        (<Link className={className} href={linkWithHost ? HOST + link : link} prefetch={false} style={style}>

            <article className={_className}>
                <OfferImage alt={alt} {...offerProps} />

                <div className={styles.body}>
                    {forList ? (
                        <>
                            <div className={styles['list-body']}>
                                <div>
                                    <Title caption={titleCaption} {...offerProps} />
                                    <Equipment {...offerProps} />
                                </div>
                                <div className={styles['price-col']}>
                                    <IsNew offer={offer} {...offerProps} />
                                    <PriceDetails inject={inject} {...offerProps} />
                                    <Installment {...offerProps} />
                                </div>
                            </div>
                            <OfferShortSpec
                                inject={inject?.spec}
                                specTextArray={specTextArray}
                                oneliner={isPart}
                            />
                            <hr />
                            <div className={styles['row']}>
                                <Firm {...offerProps} />
                                <Location {...offerProps} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Title caption={titleCaption} {...offerProps} />
                            <Equipment {...offerProps} />
                            <PriceDetails inject={inject} {...offerProps} />
                            <Installment {...offerProps} />
                            <br />
                            <OfferShortSpec
                                inject={inject?.spec}
                                specTextArray={specTextArray}
                                oneliner={isPart}
                            />
                            <hr />
                            <div className={styles['row']}>
                                <Firm {...offerProps} />
                                <Location {...offerProps} />
                            </div>
                        </>
                    )}
                </div>
            </article>

        </Link>)
    );
}

function BuyOfferListItem(offerProps) {
    const {
        featured,
        forList,
        linkWithHost,
        offer,
        inject,
        isPart,
        ...rest
    } = offerProps
	const link = offerLinkBuy(offer)
	const specTextArray = getBuySpec(offer, true)
	const [, caption, bodyType] = buyCaption(offer)
	const titleCaption = `${caption} ${bodyType}`.trim()
	const className = mix([
		styles[forList ? 'list-item' : 'grid-item'],
		!featured && isHighlighted(offer) && styles.highlighted,
	])

	return (
        (<Link href={linkWithHost ? HOST + link : link} prefetch={false} {...rest}>

            <article className={className}>
                <div className={styles.body}>
                    {forList ? (
                        <>
                            <div className={styles['list-body']}>
                                <div>
                                    <Title caption={titleCaption} pre="Kupię" {...offerProps} />
                                    <Equipment {...offerProps} />
                                </div>
                                <div className={styles['price-col']}>
                                    <IsNew offer={offer} {...offerProps} />
                                    <PriceDetails inject={inject} {...offerProps} />
                                    <Installment {...offerProps} />
                                </div>
                            </div>
                            <hr />
                            <div className={styles['row']}>
                                <OfferShortSpec
                                    inject={inject?.spec}
                                    specTextArray={specTextArray}
                                    oneliner={isPart}
                                    teal
                                />
                                <Location {...offerProps} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Title caption={titleCaption} pre="Kupię" {...offerProps} />
                            <Equipment {...offerProps} />
                            <PriceDetails inject={inject} {...offerProps} />
                            <Installment {...offerProps} />
                            <br />
                            <OfferShortSpec
                                inject={inject?.spec}
                                specTextArray={specTextArray}
                                oneliner={isPart}
                                teal
                            />
                            <hr />
                            <div className={styles['row']}>
                                <span />
                                <Location {...offerProps} />
                            </div>
                        </>
                    )}
                </div>
            </article>

        </Link>)
    );
}

function FeaturedItem(props) {
	return (
		<OfferListItem {...props} featured={true} nohighlighttag="true" />
	)
}

function GridItem(props) {
	return <OfferListItem {...props} />
}

function ListItem(props) {
	return <OfferListItem {...props} forList={true} />
}

function isHighlighted(offer) {
	const onListTop = Boolean(offer?.promotion?.search_list_top?.on)
	return onListTop
}

function buyCaption(offer) {
	const caption = buyCaptionFor(offer)
	return [caption, caption, '', '']
}

OfferListItem.Featured = FeaturedItem
OfferListItem.GridItem = GridItem
OfferListItem.ListItem = ListItem

export default OfferListItem
export {
    FeaturedItem,
    GridItem,
    ListItem,
    OfferListItem,
}
