const createSlug = require('../slug/createSlug')
const zip = require('../array/zip')

const offerSlug = ({ part_name, title, makes, models, data = {}, tracking = {}}) => {

    const city = tracking.city

    let slugComponents

    const isMakeModelBased = Boolean(makes)
    if (isMakeModelBased) {
        const zipped = zip([makes, models])
        const type = data.body_type || data.truck_body_type
        slugComponents = [zipped, type, city]
    } else {
        const name = part_name || title
        slugComponents = [name, city]
    }

    return createSlug(
        slugComponents
            .filter(Boolean)
            .join('-'))
}

module.exports = offerSlug