function netPriceToGross (offer, vat = 23) {
    const { price: priceData = 0 } = offer || {}
    const { price, price_invoice, price_is_netto } = priceData || {}
    if (!price_invoice || !price_is_netto || vat <= 0) return [false, price]
    return [true, calculateVat(price, vat)]
}

function calculateVat (price, vat = 23) {
    const tax = price * (vat * 0.01)
    const priceText = (price + tax).toFixed(2)
    return Number(priceText)
}

export {
    netPriceToGross,
    calculateVat,
}