const encodeForUrl = require('./encodeForUrl')
const offerSlug = require('./offerSlug')
const resolveLinkBase = require('./offerResolveLinkBase')

const offerLink = (offer, newScheme = shouldUseNewScheme(offer)) => {
    const linkBase = resolveLinkBase(offer)

    if (!linkBase) return '/'

    const tracking = offer.tracking || {}
    const price = offer.price || {}

    const id = offer._shortid
    const lang = tracking.lang && tracking.lang.toLowerCase() || 'pl'
    const city = encodeForUrl(tracking.city || 'bez-lokalizacji')
    const slug = encodeForUrl(offerSlug(offer) || 'bez-tytulu')

    const isRent = price.rent
    if (isRent) {
        return [linkBase, slug, id, lang].join('/')
    }

    const isMakeModelBased = Boolean(offer.make)
    if (isMakeModelBased) {
        const make = encodeForUrl(offer.pureMake || offer.make || 'bez-marki')
        const model = encodeForUrl(offer.pureModel || offer.model || 'bez-modelu')

        return (newScheme
            ? [linkBase, 'oferta', make, model, id, lang]
            : [linkBase, make, model, city, slug, id, lang]).join('/')

    } else {
        return (newScheme
            ? [linkBase, 'oferta', id, lang]
            : [linkBase, slug, id, lang]).join('/')
    }
}


function shouldUseNewScheme (offer) {
    const { tracking } = offer || {}
    const { newUrl } = tracking || {}
    return Boolean(newUrl)
}

module.exports = offerLink