import styles from './Inscription.module.css'

export default function Inscription ({ big, reserved, text }) {
    if (typeof text !== 'string' && reserved == null) return null
    return (
        <>
            { reserved && <div className={styles.reserved}>Rezerwacja</div> }
            { text && <div className={styles[big?'big':'txt']}>
                {text.toUpperCase()}
            </div> }
        </>
    )
}