const zip = (arrays, char = ' - ', funct = (arr) => arr.join(' ')) => {
    if (!Array.isArray(arrays) || arrays.length === 0) return ''
    const length = arrays[0] ? arrays[0].length : 0
    if (length === 0) return ''

    const result = []
    const parts = []
    for (let i = 0; i < length; i++) {
        for (const arr of arrays)
            if (arr[i] != null) parts.push(arr[i])

        result.push(funct(parts))
    }

    return result.join(char)
}

module.exports = zip