const {
    CAR_LINK_BASE,
    TRUCK_LINK_BASE,
    MOTORCYCLE_LINK_BASE,
    PART_LINK_BASE,
    SERVICE_LINK_BASE,
    TOOL_LINK_BASE,
    AGRI_LINK_BASE,
    CONSTRUCTION_LINK_BASE,
    TRAILER_LINK_BASE,
    HEAVY_LINK_BASE,

    CAR_RENT_LINK_BASE,
    TRUCK_RENT_LINK_BASE,
    MOTORCYCLE_RENT_LINK_BASE,
    AGRI_RENT_LINK_BASE,
    TRAILER_RENT_LINK_BASE,

    BUY_CAR_LINK_BASE,
    BUY_TRUCK_LINK_BASE,
    BUY_MOTORCYCLE_LINK_BASE,
    BUY_PART_LINK_BASE,
    BUY_CONSTRUCTION_LINK_BASE,
    BUY_TRAILER_LINK_BASE,
    BUY_TOOL_LINK_BASE,
    BUY_AGRI_LINK_BASE,
} = require('../../../src/constants/searchFormLinks')

const SELL = {
    'car_ad': CAR_LINK_BASE,
    'truck_ad': TRUCK_LINK_BASE,
    'motorcycle_ad': MOTORCYCLE_LINK_BASE,
    'part_ad': PART_LINK_BASE,
    'service_ad': SERVICE_LINK_BASE,
    'tool_ad': TOOL_LINK_BASE,
    'agri_ad': AGRI_LINK_BASE,
    'budowlane': CONSTRUCTION_LINK_BASE,
    'przyczepy': TRAILER_LINK_BASE,
    'ciezarowe': HEAVY_LINK_BASE,
}

const RENT = {
    'car_ad': CAR_RENT_LINK_BASE,
    'truck_ad': TRUCK_RENT_LINK_BASE,
    'motorcycle_ad': MOTORCYCLE_RENT_LINK_BASE,
    'agri_ad': AGRI_RENT_LINK_BASE,
    'przyczepy': TRAILER_RENT_LINK_BASE,
}

const BUY = {
    'car_ad': BUY_CAR_LINK_BASE,
    'truck_ad': BUY_TRUCK_LINK_BASE,
    'motorcycle_ad': BUY_MOTORCYCLE_LINK_BASE,
    'part_ad': BUY_PART_LINK_BASE,
    'budowlane': BUY_CONSTRUCTION_LINK_BASE,
    'przyczepy': BUY_TRAILER_LINK_BASE,
    'tool_ad': BUY_TOOL_LINK_BASE,
    'agri_ad': BUY_AGRI_LINK_BASE,
}

const resolveLinkBase = (offer, isBuy = false) => { // force buy criterion, needed in some cases
    const { type, _adtype, price } = offer || {}
    const isRent = price && price.rent

    switch (type) {
        case 'hybrid_ad':
            if (isRent) {
                return RENT[_adtype]
            } else {
                return SELL[_adtype]
            }

        case 'buy_ad':
            return BUY[_adtype]

        default:
            if (isRent) {
                return RENT[type]

            } else if (isBuy) {
                return BUY[_adtype] || BUY[type]

            } else {
                return SELL[type]
            }
    }
}

resolveLinkBase.SELL = SELL
module.exports = resolveLinkBase
