import AdvancedImage from '@components/image/WithFallback'
import cloudflarePhoto from '@utils/links/cloudflarePhoto'
import fullPhoto from '@utils/links/fullPhoto'
import makeYoutubeThumbnailResolver from '@utils/video/makeYoutubeThumbnailResolver'
import resolveCloudflareSrcSet from './functions/resolveCloudflareSrcSet'

import styles from './OfferImage.module.css'

function OfferImage ({
    alt,
    offer,
    preferredOrder = 0,
    preferredVariant = 'list',
    preferredHeight = 681,
    preferredWidth = 1024,
    src,
    variantSrcSet,
    variantSrcSetSizes,
}) {
    const { tracking, video_link } = offer || {}
    const { cloudflare, pid } = tracking || {}
    const { mainPhoto, photos = [] } = cloudflare || {}

    const hasCloudflarePhotos = mainPhoto || photos.length
    const cloudflareImageId = hasCloudflarePhotos && photos[preferredOrder] || mainPhoto
    const customFallbackResolver = video_link && makeYoutubeThumbnailResolver(video_link)

    return hasCloudflarePhotos
        ? <AdvancedImage
            alt={alt}
            className={styles['cf-image']}
            customFallbackResolver={customFallbackResolver}
            sizes={variantSrcSetSizes}
            src={src || cloudflarePhoto(cloudflareImageId, preferredVariant)}
            srcSet={variantSrcSet ? resolveCloudflareSrcSet(variantSrcSet, cloudflareImageId) : undefined}
            useNextImage={false}
          />

        : <AdvancedImage
            alt={alt}
            customFallbackResolver={customFallbackResolver}
            height={preferredHeight}
            src={src || fullPhoto(pid, preferredOrder)}
            useNextImage={true}
            width={preferredWidth}
          />
}

export {
    OfferImage,
}